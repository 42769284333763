<template>
  <div id="index-list"></div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { token } from "@/utilities/token";

import $ from "jquery";
export default {
  components: {},
  props: {
    selectedIndex: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const selectedIndex = computed({
      get: () => props.selectedIndex,
      set: (value) => emit("update:selectedIndex", value),
    });
    const buildWidgets = () => {
      MillistreamWidgetSettings.language = "sv";
      MillistreamWidgetSettings.token = token;
      new Milli_List({
        target: document.getElementById("index-list"),
        instrument: [6486, 6485, 39485, 86347, 83525, 42054, 84469],
        instrumenttype: null,
        controlcolumn: 4,
        stylecolumn: [4],
        num_decimals: 2,
        forcedecimals: true,
        tr_data_insref: true,
        fields: ["name", "symbol", "lastprice", "time", "diff1dprc"],
        timeformat: "HH:mm",
        link_field: ["insref"],
        custom_names: {
          6485: "Stockholm",
          6486: "Stockholm",
          39485: "New York",
          86347: "London",
          83525: "Frankfurt",
          42054: "Tokyo",
          84469: "Shanghai",
        },
        custom_lang: {
          diff1dprc: "Utveckling",
          symbol: " ",
          dayhighprice: "Högsta",
          daylowprice: "daylowprice",
        },
        orderby: ["insref"],
        order: ["asc"],
        onRowClick: (e) => {
          selectedIndex.value = e.insref;
          $(e.element).parent().find("tr.active").removeClass("active");
          $(e.element).addClass("active");
        },
        onreadyCallback: () => {
          console.log("nisse");
          $("#index-list > .millistream-list-table > tbody > tr:first").click();
        },
      });
    };
    onMounted(() => {
      buildWidgets();
    });
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
</style>
