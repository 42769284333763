<template>
  <Nav selected="Översikt"></Nav>
  <div id="overview-container">
    <Search
      placeholder="Sök efter aktier och fonder"
      targetId="search-div"
      :onClickFunction="searchOnClickFunction"
    ></Search>
    <div class="white-box a" id="calender-container">
      <div class="box-container">
        <div class="box-header">
          <h3>Kalender</h3>
          <div class="rectangle-button-container">
            <button
              class="rectangle-button"
              @click="calenderView = true"
              :disabled="calenderView"
            >
              Händelser
            </button>
            <button
              class="rectangle-button"
              @click="calenderView = false"
              :disabled="!calenderView"
            >
              Utdelning
            </button>
          </div>
        </div>
        <div v-show="calenderView">
          <Calender
            :list="[29934, 919325, 35207, 35208, 35209]"
            :showLink="true"
            :fields="[
              'symbol',
              'name',
              'date',
              'type',
              'marketplace',
              'instrumenttype',
            ]"
            :maxrows="5"
          ></Calender>
        </div>
        <div v-show="!calenderView">
          <Events
            :list="[29934, 919325, 35207, 35208, 35209]"
            :showLink="true"
            :maxrows="5"
            :fields="['symbol', 'name', 'date', 'dividend', 'subtype']"
            :onReadyCallback="onReadyCallbackEvents"
          ></Events>
        </div>
      </div>
    </div>
    <div class="b">
      <News
        :agency="[8212, 8223, 8192]"
        divId="newsheadlines"
        link="./news.php"
        headerText="Nyheter"
        linkText="Fler nyheter"
        :maxRows="6"
        :fields="['headline', 'symbol', 'time']"
      ></News>
    </div>
    <div id="svd-top-ad" class="ab"></div>
    <div class="white-box c" id="index" :class="showMap ? 'showing-map' : ''">
      <img v-show="showMap" src="@/assets/images/Earth.png" />
      <div class="index-list-container">
        <div class="button-container">
          <button
            class="round-button"
            @click="showMap = false"
            :disabled="!showMap"
            :class="!showMap ? 'active' : ''"
            id="chart-button"
          >
            Graf
          </button>
          <button
            class="round-button"
            @click="showMap = true"
            :class="showMap ? 'active' : ''"
            :disabled="showMap"
            id="map-button"
          >
            Karta
          </button>
          <div class="chart-button-container" v-show="!showMap">
            <button
              v-for="elem in chartButtons"
              :key="elem.name"
              :id="elem.name"
              class="round-button"
              :disabled="elem.active || elem.disabled"
              :class="elem.active ? 'active' : ''"
              @click="changeChartInterval(elem)"
            >
              {{ elem.text }}
            </button>
          </div>
        </div>
        <div class="main-section" id="list-chart" v-show="!showMap">
          <OverviewIndex
            :selectedIndex="selectedIndex"
            @update:selectedIndex="selectedIndex = $event"
          ></OverviewIndex>
          <Chart
            targetId="chart-container"
            chartlen="0d"
            :instrument="selectedIndex"
            :header="true"
            :headerCustomNames="custom_names"
            historylen="5y"
          ></Chart>
        </div>
        <div class="main-section" id="map" v-show="showMap">
          <MapIndicator
            v-for="item in mapIndicators"
            :key="item.targetId"
            :settings="item"
            :show="showMap"
          ></MapIndicator>
        </div>
      </div>
      <div class="indicator-container">
        <div class="indicator-wrapper" id="currency-indicators">
          <OverviewIndicator
            :instrument="150"
            targetId="usd-indicator"
            name="Dollar"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="926"
            targetId="euro-indicator"
            name="Euro"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="72825"
            targetId="gbp-indicator"
            name="Pund"
          ></OverviewIndicator>
        </div>
        <div class="indicator-wrapper btc-wrapper-mobile">
          <OverviewIndicator
            :instrument="42051"
            targetId="brent-indicator"
            name="Olja (Brent)"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="72831"
            targetId="guld-indicator"
            name="Guld"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="3351876"
            targetId="btc-indicator-mobile"
            name="Bitcoin"
            :lastpriceDecimals="0"
          ></OverviewIndicator>
        </div>
        <div class="indicator-wrapper btc-wrapper-desktop">
          <OverviewIndicator
            :instrument="3351876"
            targetId="btc-indicator-desktop"
            name="Bitcoin"
            :lastpriceDecimals="0"
          ></OverviewIndicator>
        </div>
        <div class="indicator-wrapper">
          <OverviewIndicator
            :instrument="33377"
            targetId="intrest-3m-indicator"
            name="Ränta 3 mån."
            pricetype="yield"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="33382"
            targetId="intrest-5y-indicator"
            name="Ränta 5 år"
            pricetype="yield"
          ></OverviewIndicator>
          <OverviewIndicator
            :instrument="33383"
            targetId="intrest-10y-indicator"
            name="Ränta 10 år"
            pricetype="yield"
          ></OverviewIndicator>
        </div>
      </div>
    </div>
    <div class="white-box d" id="thermo">
      <Thermometer></Thermometer>
    </div>
    <div id="winners-losers" class="e">
      <div class="white-box">
        <OverviewTopList :winners="true"></OverviewTopList>
      </div>
      <div class="white-box e">
        <OverviewTopList :winners="false"></OverviewTopList>
      </div>
    </div>
    <!-- <div class="white-box f"></div> -->
    <div class="white-box g">
      <List
        v-bind="myListSettings"
        v-if="myListSettings.instrument.length > 0"
      ></List>
      <div class="box-container description" v-else>
        <h3>Min lista</h3>
        <p>
          Lägg till aktier, fonder, valutor, och mer i din egen lista genom att
          klicka på
          <img src="@/assets/images/empty_star.svg" /> i listor och detaljsidor.
        </p>
      </div>
    </div>
    <div class="h" id="svd-right-ad"></div>
    <div class="white-box i">
      <List v-bind="branschIndexSettings"></List>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, reactive, watch } from "vue";
import Nav from "@/components/Nav.vue";
import Calender from "@/components/Calender.vue";
import Events from "@/components/Events.vue";
import Chart from "@/components/Chart.vue";
import OverviewIndex from "@/components/OverviewIndex.vue";
import News from "@/components/News.vue";
import OverviewTopList from "@/components/OverviewTopList.vue";
import List from "@/components/List.vue";
import OverviewIndicator from "@/components/OverviewIndicator.vue";
import MapIndicator from "@/components/MapIndicator.vue";
import Thermometer from "@/components/Thermometer.vue";
import Search from "@/components/Search.vue";
import { searchOnClickFunction } from "@/utilities/SearchHelper.js";
import { cookieName, domain } from "@/utilities/cookie.js";
import { getMapIndicators } from "@/utilities/mapIndicators.js";
import { indexList } from "@/utilities/indexList.js";
import { disableDowJonesIndices, dowJones } from "../../utilities/indexList";
export default {
  components: {
    Nav,
    Calender,
    OverviewIndex,
    News,
    OverviewTopList,
    List,
    OverviewIndicator,
    MapIndicator,
    Thermometer,
    Events,
    Search,
    Chart,
  },
  setup() {
    //MYLIST
    const myListSettings = {
      headerText: "Min lista",
      linkText: "Till min lista",
      linkHref: "./mylist.php",
      maxrows: 10,
      fields: ["symbol", "name", "diff1dprc", "lastprice"],
      targetId: "my-list",
      orderby: ["insref"],
      stylecolumn: [2],
      controlcolumn: 2,
      sortable: false,
      onRowClick: (e) => {
        if (e.instrumenttype === 4)
          document.location.href = "detailed.php?insref=" + e.insref;
        if (e.instrumenttype === 6)
          document.location.href = "index.php?insref=" + e.insref;
        if (e.instrumenttype === 8)
          document.location.href = "funddetailed.php?insref=" + e.insref;
        if (e.instrumenttype === 10)
          document.location.href = "currency.php?insref=" + e.insref;
        if (e.instrumenttype === 21)
          document.location.href = "materials.php?insref=" + e.insref;
      },
      link_field: ["insref", "instrumenttype"],
    };
    const onReadyCallbackEvents = () => {
      let table = document
        .getElementById("dividend-list")
        .querySelector(".millistream-events-table");
      let tbody = table.tBodies[0];
      let rows = tbody.querySelectorAll("tr");
      rows.forEach((element) => {
        let dividend = element.querySelector(".millistream-dividend");
        let subtype = element.querySelector(".millistream-subtype");
        subtype.textContent += " " + dividend.textContent;
      });
    };
    // KALENDER
    const calenderView = ref(true);
    const calenderButtons = [
      {
        text: "Händelser",
        name: "events",
        type: "calender-options",
        checked: true,
      },
      {
        text: "Utdelning",
        name: "dividend",
        type: "calender-options",
        checked: false,
      },
    ];
    //INDEX/CHART/MAP MAIN DIV
    const showMap = ref(false);
    const mapIndicators = getMapIndicators();
    const chartButtons = reactive([
      {
        text: "Idag",
        value: "0d",
        active: true,
      },
      {
        text: "1 dag",
        value: "1d",
        active: false,
      },
      {
        text: "1 vecka",
        value: "5d",
        active: false,
      },
      {
        text: "3 mån",
        value: "3m",
        active: false,
      },
      {
        text: "i år",
        value: "ytd",
        active: false,
      },
      {
        text: "1 år",
        value: "1y",
        active: false,
      },
      {
        text: "5 år",
        value: "5y",
        active: false,
      },
    ]);
    const changeChartInterval = (selected) => {
      let old = chartButtons.find((elem) => elem.active === true);
      old.active = false;
      selected.active = true;
      let timeperiod = document.getElementById("time-period");
      timeperiod.value = selected.value;
      timeperiod.dispatchEvent(new Event("change"));
    };
    //BRANSCHINDEX
    const branschIndexSettings = {
      headerText: "Branschindex",
      linkText: "Till alla index",
      linkHref: "./index.php",
      instrument: [
        4999813, 323658, 323717, 323711, 323691, 323670, 323707, 323663,
        4999815, 323722, 323703,
      ],
      orderby: ["insref"],
      fields: ["name", "lastprice", "diff1dprc"],
      targetId: "bransch-list",
      custom_names: {
        323658: "Energi",
        323717: "Fastigheter",
        323711: "Finans",
        323691: "Hälsovård",
        323670: "Industri",
        4999813: "Dagligavaror",
        323707: "Kraftförsörjning",
        323663: "Råvaror",
        4999815: "Sällanköpsvaror",
        323722: "Teknik",
        323703: "Telekom",
      },
      stylecolumn: [2],
      controlcolumn: 2,
      sortable: false,
      // href: "detailed.php?",
      // link_field: ["insref"],
    };
    // CHART
    const custom_names = {
      6485: "Stockholm",
      6486: "Stockholm",
      39485: "New York",
      86347: "London",
      83525: "Frankfurt",
      42054: "Tokyo",
      84469: "Shanghai",
    };
    const selectedIndex = ref(6486);
    const changeMainView = (state) => {
      switch (state) {
        case "map-button":
          showMap.value = true;
          break;
        default:
          showMap.value = false;
          break;
      }
    };
    onBeforeMount(() => {
      // milli_widget_delete_cookie('mysvdlist');
      // document.cookie = "mysvdlist=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      myListSettings.instrument = milli_widget_get_cookie_array(
        cookieName,
        domain
      );
    });

    watch(selectedIndex, () =>
      disableDowJonesIndices({
        selectedIndex: selectedIndex.value,
        intervalButtons: chartButtons,
        changeChartInterval,
      })
    );
    return {
      calenderButtons,
      onReadyCallbackEvents,
      selectedIndex,
      custom_names,
      myListSettings,
      branschIndexSettings,
      changeMainView,
      showMap,
      mapIndicators,
      calenderView,
      chartButtons,
      searchOnClickFunction,
      changeChartInterval,
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/global.scss";
@font-face {
  font-family: "SuecaSans";
  src: url("../../assets/fonts/sueca-sans-regular.woff") format("woff");
}
@font-face {
  font-family: "Sueca Hd";
  src: url("../../assets/fonts/sueca-web-hd-bold.woff") format("woff");
}
.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    font-weight: 600;
    font-style: normal;
    text-align: left;
  }
}
#main {
  width: 100%;
}
#overview-container {
  .index-list-container {
    position: relative;
    // display: flex;
    // flex-direction: column;
    // gap: 16px;
    .button-container {
    }
  }
  #search-container {
    grid-area: search;
  }
  .a {
    grid-area: a;
  }

  .b {
    grid-area: b;
  }
  .ab {
    grid-area: ab;
  }
  .c {
    grid-area: c;
  }

  .d {
    grid-area: d;
  }

  .e {
    grid-area: e;
  }

  .f {
    grid-area: f;
  }

  .g {
    grid-area: g;
  }

  .h {
    grid-area: h;
  }

  .i {
    grid-area: i;
  }
  .chart-button-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-left: auto;
  }
  #index-list {
    table.millistream-list-table {
      display: block;
    }
    thead {
      width: 100%;
      display: block;
      font-family: SuecaSans;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: rgba(37, 45, 64, 0.5);
      tr {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-areas: "a c d e ";
        padding: 0px 8px;
        & th {
          display: block;
        }
        & .millistream-name {
          text-align: left;
          grid-area: a;
        }
        & .millistream-symbol {
          grid-area: b;
        }
        & .millistream-lastprice {
          grid-area: c;
        }
        & .millistream-time {
          grid-area: d;
        }
        & .millistream-diff1dprc {
          grid-area: e;
        }
      }
    }
    tbody {
      display: block;
      tr {
        display: grid;
        padding: 8px;
        cursor: pointer;
        border-radius: 4px;
        grid-template-columns: 2fr 1fr 2fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "a b c d e";
        place-items: center;
        td {
          display: block;
        }
        .millistream-name {
          // ACTUAL FONTSIZE 15 height 18, changed to fit mobile.
          justify-self: flex-start;
          text-align: left;
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #252d40;
          grid-area: a;
        }
        .millistream-symbol {
          grid-area: b;
        }
        .millistream-lastprice {
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #252d40;
          grid-area: c;
        }
        .millistream-time {
          // text-align: right;
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #252d40;
          grid-area: d;
        }
        .millistream-diff1dprc {
          background: rgba(37, 45, 64, 0.04);
          border-radius: 28px;
          padding: 6px 0;
          font-size: 12px;
          line-height: 14px;
          width: 70px;
          font-weight: 600;
          grid-area: e;
        }
      }
      tr:hover,
      tr.active {
        background-color: $lightgray;
        td.millistream-symbol,
        td.millistream-diff1dprc {
          background: #fffcf8;
        }
      }
    }
  }

  #dividend-list .millistream-events-table {
    margin-bottom: 1rem;
    width: 100%;
    & thead {
      display: none;
    }
    & tbody {
      & tr {
        width: 100%;
        padding: 14px 0;
        display: grid;
        gap: 6px 0px;
        cursor: pointer;
        grid-template-areas:
          "a c"
          "b d";
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
        align-items: baseline;
        & .millistream-name {
          grid-area: b;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          color: $excerpt;
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }

        & .millistream-date {
          font-family: SuecaSans;
          justify-self: self-end;
          align-self: center;
          color: $labels;
          grid-area: c;
          font-style: normal;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 18px;
        }
        & .millistream-subtype {
          font-family: SuecaSans;
          white-space: nowrap;
          grid-area: d;
          justify-self: self-end;
          color: $labels;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }
        & .millistream-dividend {
          display: none;
        }
        & .millistream-symbol {
          font-family: SuecaSans;
          grid-area: a;
          justify-self: self-start;
          padding: 4px 12px;
          background: rgba(37, 45, 64, 0.04);
          border-radius: 2px;
          a {
            color: $labels;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-decoration: none;
            border-bottom: thin solid transparent;
            transition: 300ms;
            &:hover {
              border-color: $labels;
            }
          }
        }
      }
    }
  }
  #calender-container {
    .box-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    #calender .millistream-calendar-table {
      margin-bottom: 1rem;
      width: 100%;
      & thead {
        display: none;
      }
      & tbody {
        & tr {
          width: 100%;
          padding: 14px 0;
          display: grid;
          gap: 6px;
          grid-template-areas:
            "a c"
            "b d";
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
          cursor: pointer;
          align-items: baseline;
          & .millistream-name {
            grid-area: b;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            color: $excerpt;
            font-family: Sueca Hd;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
          }
          & .millistream-date {
            font-family: SuecaSans;
            justify-self: self-end;
            align-self: center;
            color: $labels;
            grid-area: c;
            font-style: normal;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 18px;
          }
          & .millistream-type {
            font-family: SuecaSans;
            white-space: nowrap;
            grid-area: d;
            justify-self: self-end;
            color: $labels;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
          }
          & .millistream-symbol {
            font-family: SuecaSans;
            grid-area: a;
            justify-self: self-start;
            padding: 4px 12px;
            background: rgba(37, 45, 64, 0.04);
            border-radius: 2px;
            a {
              color: $labels;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              text-decoration: none;
              border-bottom: thin solid transparent;
              transition: 300ms;
              &:hover {
                border-color: $labels;
              }
            }
          }
        }
      }
    }
    & .box-header {
      display: flex;
      gap: 9px;
      flex-direction: column;
    }
    & .button-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    & .rectangle-button {
      width: 50%;
    }
  }
  #winners-losers {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .box-header {
      margin-bottom: 0.5rem;
    }
  }
  #my-list .millistream-list-table {
    margin-bottom: 1rem;
    width: 100%;
    & thead {
      display: none;
    }
    & tbody {
      & tr {
        width: 100%;
        padding: 12px 0;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "a c"
          "b d";
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
        gap: 4px;
        cursor: pointer;

        align-items: center;
        & .millistream-name {
          grid-area: b;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 195px;
          color: #000000;
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
        & .millistream-diff1dprc {
          font-family: SuecaSans;
          justify-self: self-end;
          grid-area: c;
          font-style: normal;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
          padding: 4px;
          width: 60px;
          line-height: 14px;
          background: rgba(37, 45, 64, 0.04);
          border-radius: 28px;
          font-weight: 600;
        }
        & .millistream-lastprice {
          font-family: Sueca Hd;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }
        .millistream-symbol {
          font-family: SuecaSans;
          grid-area: a;
          justify-self: self-start;
          padding: 4px 12px;
          a {
            color: $labels;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-decoration: none;
            border-bottom: thin solid transparent;
            transition: 300ms;
            &:hover {
              border-color: $labels;
            }
          }
        }
      }
    }
  }
}
#bransch-list {
  .millistream-list-table {
    width: 100%;
    margin-bottom: 1rem;
    thead {
      display: none;
    }
    tbody tr {
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      gap: 0.5rem;
      padding: 0.7rem 0;
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
      td.millistream-name {
        text-align: left;
        font-family: Sueca Hd;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
      td.millistream-diff1dprc {
        justify-self: right;
        background: rgba(37, 45, 64, 0.04);
        border-radius: 28px;
        padding: 6px 0;
        font-size: 12px;
        line-height: 14px;
        width: 70px;
        font-weight: 600;
      }
      td.millistream-lastprice {
        font-family: Sueca Hd;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }
  }
}
.indicator-container {
  width: 100%;
  display: flex;
  gap: 1rem;
  .indicator-wrapper {
    display: flex;
    justify-content: space-between;
    background: rgba(37, 45, 64, 0.04);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    gap: 1rem;
  }
}
.button-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
@media (min-width: 781px) {
  #overview-container {
    display: grid;
    row-gap: 1rem;
    column-gap: 24px;
    // grid-template-areas:
    // 	"a search search"
    // 	"a ab ab"
    // 	"a c c"
    // 	"b c c"
    // 	"b c c"
    // 	"b d g"
    // 	"b e g"
    // 	"b e h"
    // 	". e i"
    // 	". e .";
    grid-template-areas:
      "a search search"
      "a ab ab"
      "a c c"
      "b c c"
      "b c c"
      "b d g"
      "b e g"
      "b e h"
      ". e i"
      ". e .";
    grid-template-columns: 250px 656px 300px;
    grid-template-rows: auto;
    justify-content: center;
    align-items: start;
    #index {
      align-self: end;
    }
    .showing-map {
      position: relative;
      img {
        position: relative;
        height: 425px;
        display: unset;
      }
      div.index-list-container {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
      }
    }
    #newsheadlines {
      & .millistream-news-table {
        width: 100%;
        & thead {
          display: none;
        }
        & tbody {
          & tr {
            display: grid;
            justify-content: start;
            align-items: center;
            gap: 5px 8px;
            padding: 12px 0;
            grid-template-areas:
              "headline headline headline"
              "symbol time .";
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
            &:first-child {
              padding-top: 0;
            }
            & td.millistream-headline {
              justify-self: flex-start;
              text-align: left;
              grid-area: headline;
              font-family: Sueca Hd;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: $excerpt;
              cursor: pointer;
            }
            & td.millistream-symbol {
              font-family: SuecaSans;
              text-align: center;
              grid-area: symbol;
              justify-self: self-start;
              padding: 4px 12px;
              background: rgba(37, 45, 64, 0.04);
              border-radius: 2px;
              color: $labels;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
            }
            & td.millistream-time {
              grid-area: time;
              justify-self: self-start;
              font-family: SuecaSans;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              color: $purple1;
            }
          }
        }
      }
    }

    .index-list-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 1rem 6px 1rem;
      & #list-chart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        .chart-wrapper {
          gap: 0.5rem;
          width: 516px;
          .chart-header {
            .box-container {
              padding-left: 1rem;
            }
            table {
              width: 100%;
              thead {
                display: none;
              }
              tbody {
                tr {
                  // font-family: Sueca Hd;
                  font-style: normal;
                  font-weight: 600;
                  text-align: right;
                  font-size: 16px;
                  // line-height: 16px;
                  td {
                    &.millistream-name {
                      text-align: left;
                      font-family: Sueca Hd;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 22px;
                      width: 25%;
                      line-height: 26px;
                      /* Footer */
                      color: #252d40;
                    }
                    &.millistream-symbol {
                      background: rgba(37, 45, 64, 0.08);
                      padding: 4px 6px;
                      font-family: SuecaSans;
                      display: block;
                      text-align: center;
                      margin: 3px 0;
                      font-style: normal;
                      border-radius: 2px;
                      font-weight: bold;
                      font-size: 10px;
                      width: fit-content;
                      line-height: 12px;
                      color: rgba(37, 45, 64, 0.5);
                    }
                    &.millistream-diff1dprc {
                      background: rgba(37, 45, 64, 0.04);
                      border-radius: 28px;
                      padding: 6px 0;
                      font-size: 12px;
                      line-height: 14px;
                      width: 70px;
                      text-align: center;
                      font-weight: 600;
                      &.positive {
                        color: $green;
                        &::before {
                          margin-right: 3px;
                          content: url("~@/assets/images/arrow_green.png");
                          vertical-align: text-top;
                        }
                      }
                      &.negative {
                        color: $red;
                        &::before {
                          margin-right: 3px;
                          vertical-align: text-top;

                          content: url("~@/assets/images/arrow_red.png");
                        }
                      }
                      &::after {
                        content: "%";
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #chart-container {
          height: 309px;
          width: 100%;
        }
      }
      #index-list {
        width: 416px;
        table {
          thead {
            tr {
              grid-template-columns: 2fr 2fr 1fr 1fr;
              th {
                &.millistream-lastprice {
                  text-align: right;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &.millistream-lastprice {
                  justify-self: end;
                }
              }
            }
          }
        }
      }
      & #map {
        width: 100%;
        #map-kanada {
          position: absolute;
          left: 8rem;
          top: 4rem;
        }
        #map-dowjones {
          position: absolute;
          left: 15rem;
          top: 7rem;
        }
        #map-mexiko {
          position: absolute;
          left: 6rem;
          top: 12rem;
        }
        #map-brasilien {
          position: absolute;
          left: 19rem;
          top: 18rem;
        }
        #map-london {
          position: absolute;
          left: 25rem;
          top: 5.5rem;
        }
        #map-frankfurt {
          position: absolute;
          left: 29rem;
          top: 8.5rem;
        }
        #map-sydafrika {
          position: absolute;
          left: 27rem;
          top: 21rem;
        }
        #map-moskva {
          position: absolute;
          left: 36rem;
          top: 3.5rem;
        }
        #map-tokyo {
          position: absolute;
          left: 48rem;
          top: 8rem;
        }
        #map-sydkorea {
          position: absolute;
          left: 48rem;
          top: 11rem;
        }
        #map-hongkong {
          position: absolute;
          left: 44rem;
          top: 14rem;
        }
        #map-australien {
          position: absolute;
          left: 43rem;
          top: 21rem;
        }
      }
    }

    .indicator-container {
      padding: 1rem;
      flex-wrap: wrap;
      box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.16);
      .indicator-wrapper {
      }
      .btc-wrapper-mobile {
        > :last-child {
          display: none;
        }
      }

      & > :last-child {
        margin-left: auto;
      }
    }
  }
}
@media (max-width: 781px) {
  #overview-container {
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "search"
      "ab"
      "c"
      "g"
      "d"
      "e"
      "f"
      "b"
      "i"
      "h"
      "a";
    .indicator-container {
      flex-direction: column;
      padding: 0 1rem;
      .indicator-wrapper {
        justify-content: space-between;
        & > div {
        }
      }
      .btc-wrapper-desktop {
        display: none;
      }
    }
    .index-list-container {
      .main-section {
        display: flex;
        flex-direction: column-reverse;
      }
      .chart-wrapper {
        .chart-header {
          padding: 0 0.5rem;
          table {
            width: 100%;
            thead {
              display: none;
            }
            tbody {
              tr {
                // font-family: Sueca Hd;
                font-style: normal;
                font-weight: 600;
                text-align: right;
                font-size: 16px;
                // line-height: 16px;
                td {
                  &.millistream-name {
                    text-align: left;
                    font-family: Sueca Hd;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    width: 45%;
                    line-height: 26px;
                    /* Footer */
                    color: #252d40;
                  }
                  &.millistream-symbol {
                    background: rgba(37, 45, 64, 0.08);
                    padding: 4px 6px;
                    font-family: SuecaSans;
                    display: block;
                    text-align: center;
                    margin: 3px 0;
                    font-style: normal;
                    border-radius: 2px;
                    font-weight: bold;
                    font-size: 10px;
                    width: fit-content;
                    line-height: 12px;
                    color: rgba(37, 45, 64, 0.5);
                  }
                  &.millistream-diff1dprc {
                    background: rgba(37, 45, 64, 0.04);
                    border-radius: 28px;
                    padding: 6px 0;
                    font-size: 12px;
                    line-height: 14px;
                    width: 70px;
                    text-align: center;
                    font-weight: 600;
                    &.positive {
                      color: $green;
                      &::before {
                        margin-right: 3px;
                        content: url("~@/assets/images/arrow_green.png");
                        vertical-align: text-top;
                      }
                    }
                    &.negative {
                      color: $red;
                      &::before {
                        margin-right: 3px;
                        vertical-align: text-top;

                        content: url("~@/assets/images/arrow_red.png");
                      }
                    }
                    &::after {
                      content: "%";
                    }
                  }
                }
              }
            }
          }
        }

        #chart-container {
          height: 300px;
        }
      }
      .button-container {
        justify-content: center;
        margin-bottom: 1rem;
        #chart-button,
        #map-button {
          display: none;
        }
        .chart-button-container {
          margin-left: unset;
          & button {
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(6) {
              display: none;
            }
          }
        }
      }
      #index-list {
        margin-top: 0;
        table {
          thead {
            tr {
              padding: 0.5rem;
              grid-template-columns: 3fr 2fr 1fr 70px;
              text-align: center;
              th {
                &.millistream-diff1dprc {
                  text-align: right;
                }
              }
            }
          }
          tbody {
            tr {
              grid-template-columns: 2fr 1fr 2fr 1fr 70px;
              border-radius: 0px;
              td {
                &.millistream-diff1dprc {
                  width: 60px;
                  text-align: center;
                  justify-self: flex-end;
                }
              }
            }
          }
        }
      }
    }

    #index {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;
      .header {
        display: none;
      }
    }
    #calender-container {
      #calender {
        table.millistream-calendar-table {
          tbody {
            tr {
              td {
                &.millistream-name {
                  width: 190px;
                }
              }
            }
          }
        }
      }
    }
    #newsheadlines {
      & .millistream-news-table {
        & thead {
          display: none;
        }
        & tbody {
          & tr {
            display: grid;
            grid-template-columns: 1fr 2fr 5fr;
            gap: 5px;
            place-items: center;
            padding: 12px 0;
            grid-template-areas:
              "headline headline headline"
              "symbol time .";
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
            &:first-child {
              padding-top: 0;
            }
            & td.millistream-headline {
              justify-self: flex-start;
              text-align: left;
              grid-area: headline;
              font-family: Sueca Hd;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: $excerpt;
              cursor: pointer;
            }
            & td.millistream-headline {
              justify-self: flex-start;
              text-align: left;
              grid-area: headline;
              // font-family: Sueca Hd;
              a {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: $excerpt;
                text-decoration: none;
                border-bottom: thin solid transparent;
                transition: 300ms;
                &:hover {
                  border-color: #000000;
                }
              }
            }
            & td.millistream-symbol {
              font-family: SuecaSans;
              text-align: center;
              grid-area: symbol;
              justify-self: self-start;
              padding: 4px 12px;
              background: rgba(37, 45, 64, 0.04);
              border-radius: 2px;
              color: $labels;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
            }
            & td.millistream-time {
              margin-right: auto;
              grid-area: time;
              justify-self: self-end;
              font-family: SuecaSans;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              color: $purple1;
            }
          }
        }
      }
    }
  }
  #bransch-list {
    .millistream-list-table {
      tbody tr {
        grid-template-columns: 2fr 1fr 1fr;
        gap: 1rem;
      }
    }
  }
}
</style>
